/* NOTE: use enums if your data is explicitly referenced in some form of validation.
   option arrays can then be derived from enums
   ex: [...Object.values(basketTypes)]
*/

export interface OptionField {
  label: string;
  value: any;
  key?: any;
}

export const includeNoSelection = (
  data: string[],
  noSelectionLabel: string = 'No Selection',
) => {
  return [
    {
      label: noSelectionLabel,
      value: null,
      key: 'noSelection',
    },
    ...data.map((x: string) => {
      return { label: x, value: x, key: x };
    }),
  ];
};

export const includeNoSelectionOnObjectArray = (data: OptionField[]) => {
  return [
    {
      label: 'No Selection',
      value: null,
      key: 'noSelection',
    },
    ...data.map((x: OptionField) => {
      return { label: x.label, value: x.value, key: x.value };
    }),
  ];
};

export const NO_SELECTION = 'No Selection';

//CH specific: don't allow edits on the screen
export enum CHProductStages {
  QUOTE = 'Quote',
  REQUEST = 'Request',
  CONFIRM_LOCK = 'Confirm Lock',
}

export enum ProductStages {
  NOT_TRADED = 'Not Traded',
  OFFER = 'Offer',
  POST_TRADE = 'Post Trade',
  OPS_REVIEW = 'Ops Review',
}

export enum IncompleteCategory {
  ADJUSTMENT_FACTOR = 'Adjustment Factor',
  AUTOCALL_LINKED_TO_CLN = 'Autocall Linked to CLN',
  BEARISH_KNOCK_OUT = 'Bearish Knock Out',
  CLN_WITH_SEVERAL_UNDERLIERS = 'CLN with Several Underliers',
  COMMODITY_CUSTOM_ROLL = 'Commodity Custom Roll',
  COUPON_CONVERSION = 'Coupon Conversion',
  CURRENCY_HEDGE = 'Currency Hedge',
  DAILY_AVERAGING_FOR_3_MONTHS = 'Daily Averaging for 3 months',
  DISPERSION_WARRANT = 'Dispersion Warrant',
  DIVIDEND_POINT_TO_POINT = 'Dividend Point to Point',
  DUAL_RANGE_ACCRUAL = 'Dual Range Accrual',
  ETN = 'ETN',
  EXCESS_RETURN_CONTINGENT_COUPON = 'Excess Return Contingent Coupon',
  EXCESS_RETURN_COUPON_OR_EXCESS_MEMORY = 'Excess Return Coupon/Excess Memory',
  EXCESS_RETURN_P_2_P = 'Excess Return P2P',
  EXCESS_RETURN_STEP_DOWN_BARRIER_SNOWBALL = 'Excess Return Step Down Barrier Snowball',
  EXTENDIBLE_ACCRUAL = 'Extendible Accrual',
  FINAL_BARRIER = 'Final Barrier',
  FINAL_VALUE_SUBTRACTION = 'Final Value Subtraction',
  KNOCKOUT_OR_KNOCKIN_NOTE = 'Knockout/Knockin Note',
  LOCK_IN_P_2_P = 'Lock-in P2P',
  LOOKBACK_FOR_INITIAL_OR_FINAL_VALUES = 'Lookback for Initial/Final Values',
  MONTHLY_AGGREGATED_OR_PEGASUS = 'Monthly Aggregated/Pegasus',
  MULTIPLE_DIGITAL_OR_DIGITAL_BARRIER = 'Multiple Digital/Digital Barrier',
  MULTI_BARRIER_EXCESS_RETURN_SNOWBALL = 'Multi Barrier Excess Return Snowball',
  MULTIPLE_FLOAT_INSTRUMENTS = 'Multiple Float Instruments',
  MULTIPLE_PARTICIPATION_RATES = 'Multiple Participation Rates',
  MISSING_FINAL_TERM_SHEET = 'Missing Final Term Sheet',
  OUT_PERFORMANCE_WITH_DIVISOR = 'Out Performance with Divisor',
  P_2_P_WITH_PORTFOLIO_REBALANCE = 'P2P with Portfolio Rebalance',
  PARTIAL_PRINCIPAL_REPAYMENT = 'Partial Principal Repayment',
  PASS_THROUGH = 'Pass Through',
  PAST_PERFORMANCE_ADJUSTED_COUPON = 'Past Performance Adjusted Coupon',
  PERFORMANCE_ALLOCATOR_NOTES = 'Performance Allocator Notes',
  PORTFOLIO_CERTIFICATE = 'Portfolio Certificate',
  PRORATED_FIRST_AND_LAST_COUPONS = 'Prorated First and Last COUPONS',
  REAL_ESTATE = 'Real Estate',
  STABILITY_NOTE_WITH_DAILY_CALCULATION = 'Stability Note with Daily Calculation',
  STOCK_EVENT_PORTFOLIO = 'Stock Event Portfolio',
  STRATEGY_MINUS_FEES = 'Strategy Minus Fees',
  TRACKER_CERTIFICATE = 'Tracker Certificate',
  TRANCHED_CLN_ZERO_RECOVERY_NOTES = 'Tranched CLN Zero Recovery Notes',
  UNKNOWN_PORTFOLIO = 'Unknown Portfolio',
  WEIGHTS_OF_BASKET_ARE_DEPENDENT_UPON_PERFORMANCE_OR_NEW_VALIDATIONS = 'Weights of Basket are Dependent upon Performance/New Validations',
}

export enum ProductStatuses {
  ACCEPTING_ORDERS = 'Accepting Orders',
  ACCEPTING_INDICATIONS = 'Accepting Indications',
  CALLED = 'Called',
  CANCELLED = 'Cancelled',
  CONFIRM_LOCK = 'Confirm Lock',
  ESTIMATED_QUOTE = 'Estimated Quote',
  EXPIRED = 'Expired',
  RETIRED = 'Retired',
  FINAL_EXECUTION = 'Final Execution',
  FINAL_NOTIONAL_CONFIRMED = 'Final Notional Confirmed',
  FINAL_QUOTE = 'Final Quote',
  INDICATIVE_QUOTE_RECEIVED = 'Indicative Quote Received',
  INDICATIVE_QUOTE = 'Indicative Quote',
  LIVE = 'Live',
  MATURED = 'Matured',
  NEW = 'New',
  OFFER = 'Offer',
  OFFERING_EXPIRED = 'Offering Expired',
  OPEN_FOR_ORDERS = 'Open for Orders',
  PENDING_CONFIRMATION = 'Pending Confirmation',
  PENDING_FINAL_DOCS = 'Pending Final Docs',
  REQUEST = 'Request',
  RESTRICTED = 'Restricted',
  REVIEW_REQUIRED = 'Review Required',
  TRADED_NOT_SETTLED = 'Traded Not Settled',
  TRADED_PENDING_DOCUMENTATION = 'Traded Pending Documentation',
  UPDATE_PRODUCT_DETAILS = 'Update Product Details',
}

export enum QuotingType {
  Dirty = 'Dirty',
  Clean = 'Clean',
}

export enum ProductCompletionStatus {
  COMPLETE = 'Complete',
  CUSTOM = 'Custom',
  INCOMPLETE = 'Incomplete',
}

export const productCompletionStatus = ['Complete', 'Custom', 'Incomplete'];

export enum ProductOpsReviewStatus {
  QUALITY_REVIEWED_OFFER = 'Quality Reviewed Offer',
  QUALITY_REVIEWED_POST_TRADE = 'Quality Reviewed Post Trade',
}

export enum RegistrationTypes {
  // CERTIFICATE_OF_DEPOSIT ='Certificate of Deposit',
  THREE_A_TWO = '3a2',
  CD = 'CD',
  EXEMPT_DISTRIBUTION = 'Exempt Distribution',
  GIC = 'GIC',
  ML_GIC = 'ML GIC',
  PAR = 'PAR',
  PPN = 'PPN',
  PRIVATE_PLACEMENT = 'Private Placement',
  REG_S = 'Reg S',
  REGISTERED_NOTE = 'Registered Note',
  EMTN = 'EMTN',
  SMA = 'SMA',
}

export enum WrapperTypes {
  CD = 'CD',
  MARKET_LINKED_TRUST = 'Market Linked Trust',
  NOTE = 'Note',
  ML_GIC = 'ML GIC',
  GIC = 'GIC',
  NOTE_REPLICATION = 'Note Replication',
  WARRANT = 'Warrant',
}

export enum TenorUnits {
  Months = 'Months',
  Weeks = 'Weeks',
  Years = 'Years',
  OPEN_ENDED = 'Open Ended',
}

export const currencyCodes = [
  'USD',
  'AED',
  'ARS',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JPY',
  'KRW',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PEN',
  'PHP',
  'PLN',
  'RUB',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'ZAR',
];

export enum BasketTypes {
  WORST_OF = 'WoF',
  BEST_OF = 'BoF',
  CUSTOM = 'Custom',
  EQUAL = 'Equal',
  SINGLE = 'Single',
  OUT_PERFORMANCE = 'Out Performance',
  RANK_WEIGHTED = 'Rank Weighted',
}

export const basketTypeOptions = [
  { label: 'Worst of', value: BasketTypes.WORST_OF },
  { label: 'Best of', value: BasketTypes.BEST_OF },
  { label: 'Custom', value: BasketTypes.CUSTOM },
  { label: 'Equal', value: BasketTypes.EQUAL },
  { label: 'Out Performance', value: BasketTypes.OUT_PERFORMANCE },
  { label: 'Single', value: BasketTypes.SINGLE },
  { label: 'Rank Weighted', value: BasketTypes.RANK_WEIGHTED },
];

export enum UnderlierSources {
  RIC = 'RIC',
  Bloomberg = 'Bloomberg',
}

export const initialFixingOptions = ['End of Day', 'Open', 'Intraday', 'VWAP'];

export enum CallTypes {
  AUTO = 'Auto',
  AUTOCALL_STEP = 'Autocall Step',
  ISSUER = 'Issuer',
  CUMULATIVE_COUPON = 'Cumulative Coupon',
  ISSUER_CALL_STEP = 'Issuer Call Step',
}

export enum ObservationFrequencies {
  'ANNUALLY' = 'Annually',
  'BI_MONTHLY' = 'Bi-Monthly',
  'BI_WEEKLY' = 'Bi-Weekly',
  'CUSTOM' = 'Custom',
  'DAILY' = 'Daily',
  'MONTHLY' = 'Monthly',
  'ON_FINAL_VALUATION_DATE' = 'On Final Valuation Date',
  'QUARTERLY' = 'Quarterly',
  'SEMI_ANNUALLY' = 'Semi-Annually',
  'WEEKLY' = 'Weekly',
  'EUROPEAN' = 'European',
}

export const ObservationFrequencyOptions = [
  { label: 'Annually', value: 'Annually' },
  { label: 'At Maturity', value: 'At Maturity' },
  { label: 'Bi-Monthly', value: 'Bi-Monthly' },
  { label: 'Bi-Weekly', value: 'Bi-Weekly' },
  { label: 'Custom', value: 'Custom' },
  { label: 'Daily', value: 'Daily' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'On Final Valuation Date', value: 'On Final Valuation Date' },
  { label: 'Quarterly', value: 'Quarterly' },
  { label: 'Semi-Annually', value: 'Semi-Annually' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'European', value: 'European', disabled: true },
];

export const minCouponFrequencies = [
  'Annually',
  'At Maturity',
  'Bi-Monthly',
  'Custom',
  'Monthly',
  'Quarterly',
  'Semi-Annually',
];

export const returnBarrierObsFrequencies = [
  'Annually',
  'At Maturity',
  'Bi-Monthly',
  'Bi-Weekly',
  'Continuous',
  'Custom',
  'Daily',
  'European',
  'Monthly',
  'On Final Valuation Date',
  'Quarterly',
  'Semi-Annually',
  'Weekly',
];

export const putObservationFrequencies: any = [
  { label: 'Annually', value: 'Annually' },
  { label: 'At Maturity', value: 'At Maturity' },
  { label: 'Bi-Monthly', value: 'Bi-Monthly' },
  { label: 'Bi-Weekly', value: 'Bi-Weekly' },
  { label: 'Continuous', value: 'Continuous' },
  { label: 'Custom', value: 'Custom' },
  { label: 'Daily', value: 'Daily' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Quarterly', value: 'Quarterly' },
  { label: 'Semi-Annually', value: 'Semi-Annually' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'European', value: 'European', disabled: true },
];

export const rateBarrierObservationFrequencies = [
  'Annually',
  'At Maturity',
  'Bi-Weekly',
  'Daily',
  'European',
  'Monthly',
  'Quarterly',
  'Semi-Annually',
  'Weekly',
];

export enum GrowthTypes {
  ATM_DIGITAL = 'ATM Digital',
  CAPPED_ATM_DIGITAL = 'Capped ATM Digital',
  CAPPED_DIGITAL_DUAL_DIRECTIONAL = 'Capped Digital Dual Directional',
  CAPPED_DUAL_DIRECTIONAL = 'Capped Dual Directional',
  CAPPED_HYBRID_CREDIT_LINKED_POINT_TO_POINT = 'Capped Hybrid Credit Linked Point to Point',
  CAPPED_ITM_DIGITAL = 'Capped ITM Digital',
  CAPPED_OTM_DIGITAL = 'Capped OTM Digital',
  CAPPED_POINT_TO_POINT = 'Capped Point to Point',
  CLIQUET = 'Cliquet',
  COUPON_EXCESSIVE_RETURN = 'Coupon Excessive Return',
  DIGITAL_DUAL_DIRECTIONAL = 'Digital Dual Directional',
  DUAL_DIRECTIONAL = 'Dual Directional',
  HYBRID_CREDIT_LINKED_POINT_TO_POINT = 'Hybrid Credit Linked Point to Point',
  ITM_DIGITAL = 'ITM Digital',
  LEVERAGED_BUILDER = 'Leveraged Builder',
  OTM_DIGITAL = 'OTM Digital',
  POINT_TO_POINT = 'Point to Point',
  SNOWBALL = 'Snowball',
  UNCAPPED_ATM_DIGITAL = 'Uncapped ATM Digital',
  UNCAPPED_DIGITAL_DUAL_DIRECTIONAL = 'Uncapped Digital Dual Directional',
  UNCAPPED_ITM_DIGITAL = 'Uncapped ITM Digital',
  UNCAPPED_OTM_DIGITAL = 'Uncapped OTM Digital',
  VARIABLE_SNOWBALL = 'Variable Snowball',
}

export const callPeriodObservationTypes = ['Continuous', 'Daily', 'European'];

export enum StepTypes {
  UP = 'Up',
  DOWN = 'Down',
}

export const minMax = ['Min', 'Max'];

export const yesNo = ['Yes', 'No'];

export const highLow = ['High', 'Low'];

export enum CouponTypes {
  ALL_UP = 'All Up',
  ANNUAL_AVERAGING = 'Annual Averaging',
  AUTO_CAP = 'AutoCap',
  BUILDER = 'Builder',
  CLIQUET = 'Cliquet',
  CONTINGENT = 'Contingent',
  CREDIT_LINKED = 'Credit Linked',
  FIXED_INTEREST_CREDIT_LINKED = 'Fixed Interest Credit Linked',
  FIXED_TO_CONTINGENT = 'Fixed To Contingent',
  FIXED_TO_FLOATING = 'Fixed To Floating',
  FLOATING_TO_FIXED = 'Floating To Fixed',
  FIXED_TO_RESET = 'Fixed to Reset',
  FIXED = 'Fixed',
  PRINCIPAL_REPAYMENT = 'Principal Repayment',
  RANGE_ACCRUAL = 'Range Accrual',
  RATE_BUILDER = 'Rate Builder',
  RATE_LINKED = 'Rate Linked',
  RESETTING_ANNUAL_AVERAGING = 'Resetting Annual Averaging',
  RESETTING_TIME_SERIES = 'Resetting Time Series',
  STOCK_BASKET = 'Stock Basket',
  TIME_SERIES = 'Time Series',
  VARIABLE_INTEREST_CREDIT_LINKED = 'Variable Interest Credit Linked',
}

export enum CouponStepTypes {
  UP = 'Up',
  DOWN = 'Down',
  CUSTOM = 'Custom',
}

export enum PaymentBarrierObservationFrequencies {
  CONTINUOUS = 'Continuous',
  DAILY = 'Daily',
  EUROPEAN = 'European',
  ON_FINAL_OBSERVATION = 'On Final Observation',
  QUARTERLY = 'Quarterly',
}

export enum ProtectionTypes {
  BARRIER = 'Barrier',
  BUFFER = 'Buffer',
  FULL = 'Full',
  GEARED_BARRIER = 'Geared Barrier',
  GEARED_BUFFER = 'Geared Buffer',
  NO_PRINCIPAL_PROTECTION = 'No Principal Protection',
  PARTIAL = 'Partial',
}

export const offeringTypes = ['Private Placement', 'Public Offering'];

export const countries = [
  'Argentina',
  'Austria',
  'Belgium',
  'Canada',
  'Chile',
  'Colombia',
  'Denmark',
  'Ecuador',
  'Finland',
  'France',
  'Germany',
  'Ireland',
  'Israel',
  'Italy',
  'Luxembourg',
  'Mexico',
  'Netherlands',
  'Norway',
  'Panama',
  'Paraguay',
  'Peru',
  'Portugal',
  'Puerto Rico',
  'South Africa',
  'Spain',
  'Sweden',
  'Switzerland',
  'UAE',
  'United Kingdom',
  'Uruguay',
  'USA',
];

export const listings = [
  'BME',
  'BVME',
  'BX Swiss',
  'Euronext Brussels',
  'Euronext France',
  'Euronext Lisbon',
  'Euronext NL',
  'FWB',
  'GETTEX',
  'LSE',
  'LuxSE',
  'None',
  'SIX',
  'SWB',
  'WBAG',
];

export const quotingTypes = ['Clean', 'Dirty'];

export const targetMarkets = [
  'Eligible Counterparties',
  'Professional Counterparties',
  'Retail',
];

export const ratePaymentTypes = [
  'Floating Rate',
  'Rate Conditional Coupon',
  'Rate Range Accrual',
  'Dual Range Accrual',
  'Range Accrual Underlier',
];

export const swapMaturities = ['1mL', '3mL', '1m', '3m', '6m'];
export enum AncillaryFeature {
  With_Aggregating = 'With Aggregating',
  With_Lookback_Initial = 'With Lookback Initial',
  With_Lookback_Final = 'With Lookback Final',
  With_Coupon_Memory = 'With Coupon Memory',
  With_Minimum_Return = 'With Minimum Return',
  With_Minimum_Coupon = 'With Minimum Coupon',
  With_Averaging_In = 'With Averaging In',
  With_Averaging_Out = 'With Averaging Out',
  With_Modified_Averaging_In = 'With Modified Averaging In',
  With_Modified_Averaging_Out = 'With Modified Averaging Out',
  With_Supplemental = 'With Supplemental',
  With_Knock_Out = 'With Knock Out',
}

export const MODIFIED_FOLLOWING = 'Modified Following';

export const productCodes = ['CORN', 'CORP', 'ELNC', 'ELNE', 'ELNP', 'ELNS'];

export const offeringScopes = ['Broad', 'Tailored'];

export const offshoreEligibilities = ['No', 'Offshore Only', 'Yes'];

export const interestPeriods = ['Long', 'Short', 'Standard'];

export enum TemplateOptions {
  // question: the 'all fields' option is controlled by a permission, but appears to do the same as no selection?
  // <mat-option *ngIf='user?.canAccess(widgetPermissions.ProductMaintenanceProductEntrySuper)' [value]='allFieldsTemplate'>All Fields</mat-option>
  ALL_UP = 'All Up',
  AUTO_CAP = 'AutoCap',
  BEARISH = 'Bearish',
  CAPPED_DUAL_DIRECTIONAL = 'Capped Dual Directional',
  CAPPED_POINT_TO_POINT = 'Capped Point to Point',
  CLIQUET = 'Cliquet',
  CONTINGENT = 'Contingent',
  DIGITAL = 'Digital',
  DIGITAL_DUAL_DIRECTIONAL = 'Digital Dual Directional',
  DIGITAL_PLUS = 'Digital Plus',
  P2P_WITH_CONTINGENT_COUPON = 'P2P with Contingent Coupon',
  P2P_WITH_FIXED_COUPON = 'P2P with Fixed Coupon',
  RANGE_ACCRUAL = 'Range Accrual',
  RATE_BUILDER = 'Rate Builder',
  SNOWBALL = 'Snowball',
  TIME_SERIES = 'Time Series',
  UNCAPPED_DUAL_DIRECTIONAL = 'Uncapped Dual Directional',
  UNCAPPED_POINT_TO_POINT = 'Uncapped Point to Point',
  FIXED_TO_FLOATING = 'Fixed to Floating',
  FIXED = 'Fixed',
}

export enum DayCountFractions {
  ONE_ONE = '1/1',
  ONE_HUNDRED_EIGHTY_THREE_SIXTY = '180/360',
  NINETY_THREE_SIXTY = '90/360',
  thirtyThreeSixty = '30/360',
  THIRTY_THREE_SIXTY_FIVE = '30/365',
  ACTUAL_360 = 'Actual/360',
  ACTUAL_365 = 'Actual/365',
  BBD_252 = 'BBD/252',
  ACTUAL_ACTUAL = 'Actual/Actual',
}

export enum FloatInstruments {
  BPISDS01 = 'BPISDS01',
  CAONREPO = 'CAONREPO',
  CDOR03 = 'CDOR03',
  CPURNSA = 'CPURNSA',
  EUAMDB02 = 'EUAMDB02',
  EUAMDB05 = 'EUAMDB05',
  EUAMDB30 = 'EUAMDB30',
  EUR003M = 'EUR003M',
  MXIBTIIE = 'MXIBTIIE',
  SOFR = 'SOFR',
  SOFR30A = 'SOFR30A',
  SOFR90A = 'SOFR90A',
  SONION = 'SONIO/N',
  USGG10YR = 'USGG10YR',
  USISDA5 = 'USISDA5',
  USISDA10 = 'USISDA10',
  USISDA30 = 'USISDA30',
  USISOA02 = 'USISOA02',
  USISOA05 = 'USISOA05',
  USISOA10 = 'USISOA10',
  USISOA20 = 'USISOA20',
  USISOA30 = 'USISOA30',
  USISSO01 = 'USISSO01',
  USISSO02 = 'USISSO02',
  USISSO05 = 'USISSO05',
  USISSO10 = 'USISSO10',
  USISSO30 = 'USISSO30',
  USSWAP2 = 'USSWAP2',
  USSWAP5 = 'USSWAP5',
  USSWAP10 = 'USSWAP10',
  USSWAP20 = 'USSWAP20',
  USSWAP30 = 'USSWAP30',
  US0001M = 'US0001M',
  US0003M = 'US0003M',
  US0006M = 'US0006M',
  US0012M = 'US0012M',
  XB1 = 'XB1',
}

export enum CDSNames {
  AUCHAN_HOLDING_SADIR = 'Auchan Holding SADIR',
  BANCO_DO_BRASIL_S_A = 'Banco do Brasil S.A.',
  BRITISH_TELECOMMUNICATIONS_PLC = 'British Telecommunications PLC',
  AIR_FRANCE = 'Air France',
  AMAZON_COM_INC = 'Amazon.com Inc',
  AMERICAN_AIRLINES_GROUP = 'American Airlines Group',
  ANGLO_AMERICAN_PLC = 'Anglo American PLC',
  ANHEUSER_BUSCH_INBEV_SA = 'Anheuser Busch Inbev SA/NV',
  ARAB_REPUBLIC_OF_EGYPT = 'Arab Republic of Egypt',
  ARCELOR_MITTAL_SA = 'ArcelorMittal SA',
  AT_T_INC = 'AT&T Inc.',
  BANCO_BRADESCO_SA = 'Banco Bradesco SA',
  BANK_OF_AMERICA_CORP = 'Bank of America Corp',
  BARCLAYS_PLC = 'Barclays PLC',
  BASF_SE = 'BASF SE',
  BERKSHIRE_HATHAWAY_INC = 'Berkshire Hathaway Inc',
  BNP_PARIBAS_SA = 'BNP Paribas SA',
  BOEING_CO = 'Boeing Co/The',
  CAMPBELL_SOUP_CO = 'Campbell Soup Co',
  CAPITAL_ONE_FINANCIAL_CORP = 'Capital One Financial Corp',
  CARREFOUR_SA = 'Carrefour SA',
  CASINO_GUICHARD_PERRACHON_SA = 'Casino Guichard Perrachon SA',
  CCO_HOLDINGS_LLC = 'CCO HOLDINGS LLC',
  CDX_EMERGING_MARKETS_S_29 = 'CDX Emerging Markets S29',
  CDX_HY_S_26 = 'CDX HY S26',
  CDX_HY_S_29 = 'CDX HY S29',
  CDX_HY_S_35 = 'CDX HY S35',
  CDX_HY_S_37 = 'CDX HY S37',
  CDX_NA_IG_S_32 = 'CDX NA IG S32',
  CEMEX_SAB_DE_CV = 'Cemex SAB de CV',
  CHARTER_COMMUNICATIONS_INC_A = 'Charter Communications Inc A',
  CITIGROUP_INC = 'Citigroup Inc',
  COOPERATIEVE_RABOBANK_UA = 'Cooperatieve Rabobank UA',
  CREDIT_SUISSE_GROUP_AG = 'Credit Suisse Group AG',
  CRS_CORP = 'CRS Corp',
  CVS_HEALTH_CORP = 'CVS Health Corp',
  DELL_INC = 'Dell Inc',
  DEUTSCHE_BANK_AG = 'Deutsche Bank AG',
  DOW_CHEMICAL_CO = 'Dow Chemical Co/The',
  EDP_ENERGIAS_DE_PORTUGAL_SA = 'EDP Energias de Portugal SA',
  EIDP_INC = 'EIDP Inc',
  ELDORADO_BRASIL_CELULOSE_SA = 'Eldorado Brasil Celulose SA',
  ELECTRICITE_DE_FRANCE_SA = 'Electricite de France SA',
  FEDERATIVE_REPUBLIC_OF_BRAZIL = 'Federative Republic of Brazil',
  EMIRATE_OF_ABU_DHABI = 'Emirate of Abu Dhabi',
  FORD_MOTOR_CO = 'Ford Motor Co',
  FORD_MOTOR_CREDIT_CO_LLC = 'Ford Motor Credit Co LLC',
  FREEPORT_MC_MO_RAN_INC = 'Freeport McMoRan Inc',
  GENERAL_ELECTRIC_CO = 'General Electric Co',
  GENERAL_MOTORS_CO = 'General Motors Co',
  GLENCORE_INTERNATIONAL_AG = 'Glencore International AG',
  GOLDMAN_SACHS_GROUP_INC = 'Goldman Sachs Group Inc',
  GOODYEAR_TIRE_AND_RUBBER_CO = 'Goodyear Tire & Rubber Co/The',
  HILTON_DOMESTIC_OPERATING_CO_INC = 'Hilton Domestic Operating Co Inc',
  HOWMET_AEROSPACE_INC = 'Howmet Aerospace Inc',
  HSBC_HOLDINGS_PLC = 'HSBC Holdings PLC',
  I_TRAXX_CROSSOVER_S_31 = 'iTraxx Crossover S31',
  I_TRAXX_CROSSOVER_S_34 = 'iTraxx Crossover S34',
  I_TRAXX_EUROPE_CROSSOVER_S_23_V_1 = 'iTraxx Europe Crossover S23 V1',
  I_TRAXX_EUROPE_CROSSOVER_S_33_V_1 = 'iTraxx Europe Crossover S33 V1',
  I_TRAXX_EUROPE_CROSSOVER_S_36_V_1 = 'iTraxx Europe Crossover S36 V1',
  I_TRAXX_EUROPE_CROSSOVER_S_37_V_1 = 'iTraxx Europe Crossover S37 V1',
  I_TRAXX_EUROPE_CROSSOVER_S_39_V_1 = 'iTraxx Europe Crossover S39 V1',
  I_TRAXX_EUROPE_CROSSOVER_S_40_V_1 = 'iTraxx Europe Crossover S40 V1',
  I_TRAXX_EUROPE_CROSSOVER_S_41_V_1 = 'iTraxx Europe Crossover S41 V1',
  I_TRAXX_EUROPE_CROSSOVER_S_42_V_1 = 'iTraxx Europe Crossover S42 V1',
  I_TRAXX_EUROPE_S_30_V_1 = 'iTraxx Europe S30 V1',
  I_TRAXX_EUROPE_S_31_V_1 = 'iTraxx Europe S31 V1',
  I_TRAXX_EUROPE_S_34_V_1 = 'iTraxx Europe S34 V1',
  I_TRAXX_EUROPE_S_35_V_1 = 'iTraxx Europe S35 V1',
  I_TRAXX_EUROPE_S_36_V_1 = 'iTraxx Europe S36 V1',
  I_TRAXX_EUROPE_S_38_V_1 = 'iTraxx Europe S38 V1',
  I_TRAXX_EUROPE_S_39_V_1 = 'iTraxx Europe S39 V1',
  I_TRAXX_EUROPE_S_40_V_1 = 'iTraxx Europe S40 V1',
  JAGUAR_LAND_ROVER_AUTOMOTIVE_PLC = 'Jaguar Land Rover Automotive PLC',
  JAPAN = 'JAPAN',
  JOHNSON_AND_JOSHNSON = 'Johnson & Johnson',
  JPMORGAN_CHASE_AND_CO = 'JPMorgan Chase & Co.',
  KINGDOM_OF_SAUDI_ARABIA = 'Kingdom of Saudi Arabia',
  KRAFT_HEINZ_FOODS_CO = 'KRAFT HEINZ FOODS CO',
  L_BRANDS_INC = 'L Brands Inc',
  LLOYDS_BANKING_GROUP_PLC = 'Lloyds Banking Group PLC',
  MC_DONALDS_CORPORATION = "McDonald's Corporation",
  MORGAN_STANLEY = 'Morgan Stanley',
  MICROSOFT_CORPORATION = 'Microsoft Corporation',
  NISSAN_MOTOR_CO = 'Nissan Motor Co',
  PERU = 'PERU',
  PETROLEO_BRASILEIRO_SA = 'Petroleo Brasileiro SA',
  PETROLEOS_MEXICANOS = 'Petroleos Mexicanos',
  REPUBLIC_OF_CHILE = 'Republic of Chile',
  REPUBLIC_OF_COLOMBIA = 'Republic of Colombia',
  REPUBLIC_OF_ITALY = 'Republic of Italy',
  SBERBANK = 'Sberbank',
  SOCIETE_GENERALE_SUBORDINATED = 'Societe Generale Subordinated',
  STAPLES_INC = 'Staples Inc',
  STATE_OF_ISRAEL = 'State of Israel',
  STATE_OF_QATAR = 'State of Qatar',
  STELLANTIS_NV = 'Stellantis NV',
  SULTANATE_OF_OMAN = 'Sultanate of Oman',
  T_MOBILE = 'T-Mobile',
  TEVA_PHARMACEUTICAL_INDUSTRIES_LTD = 'Teva Pharmaceutical Industries ltd',
  THYSSEN_KRUPP_AG = 'ThyssenKrupp AG',
  THE_STANDARD_BANK_OF_SOUTH_AFRICA_LIMITED = 'The Standard Bank of South Africa Limited',
  UNI_CREDIT_SP_A = 'UniCredit SpA',
  UNITED_MEXICAN_STATES = 'United Mexican States',
  UNITED_STATES_OF_AMERICA = 'United States of America',
  UNITED_STATES_STEEL_CORP = 'United States Steel Corp',
  VALE_SA = 'Vale SA',
  VALERO_ENERGY_CORP = 'Valero Energy Corp',
  VERIZON_COMMUNICATIONS = 'Verizon Communications',
  VOLKSWAGEN_AG = 'Volkswagen AG',
  WELLS_FARGO_AND_COMPANY = 'Wells Fargo & Company',
}

export enum BarrierCrossings {
  BELOW = 'Below',
  AT_OR_BELOW = 'At or Below',
  ABOVE = 'Above',
  AT_OR_ABOVE = 'At or Above',
}

export enum KnockOutBarrierObservationFrequencies {
  CONTINUOUS = 'Continuous',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  BI_WEEKLY = 'Bi-Weekly',
  MONTHLY = 'Monthly',
  BI_MONTHLY = 'Bi-Monthly',
  QUARTERLY = 'Quarterly',
  SEMI_ANNUALLY = 'Semi-Annually',
  ANNUALLY = 'Annually',
  EUROPEAN = 'European',
  EUROPEAN_CONTINUOUS = 'European Continuous',
  AT_MATURITY = 'At Maturity',
  ON_FINAL_VALUATION_DATE = 'On Final Valuation Date',
  ON_FINAL_OBSERVATION = 'On Final Observation',
  CUSTOM = 'Custom',
  ONCE = 'Once',
  DAILY_CLOSING = 'DailyClosing',
}

export enum AccrualFieldReference {
  Float_Instrument = 'Float Instrument',
  Long_And_Short_Spread = 'Long and Short Spread',
}

export enum CollateralOptions {
  US_TREASURY = 'US Treasury',
  INVESTMENT_GRADE_FI_ETF = 'Investment Grade FI ETF',
  CORPORATE = 'Corporate',
  BOND_PORTFOLIO = 'Bond Portfolio',
  MUNICIPAL = 'Municipal',
  UNFUNDED = 'Unfunded',
  FLOATING = 'Floating',
}

export const originatorDeskIDs = [
  { label: 'MSWM - 1052', value: '1052' },
  { label: 'GWIM - 1090', value: '1090' },
];

export enum OptionTypes {
  CALL = 'Call',
  PUT = 'Put',
  UP_IN_CALL = 'Up in Call',
  DOWN_IN_PUT = 'Down in Put',
}

export enum OptionObservationTypes {
  EUROPEAN = 'European',
  CONTINUOUS = 'Continuous',
  DAILY = 'Daily',
  BERMUDA = 'Bermuda',
}
